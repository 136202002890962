<template>
    <div>
        <div class="contain1">
            <div class="contain_item1">绑定手机号</div>
            <div class="code_contain">
                <div class="code_contain_number">
                    <div class="contain_item2_input">
                        <img src="@/assets/image/icon12.png" alt="" />
                        <el-input placeholder="请输入账号/手机号" v-model="bindinfo.mobile">
                        </el-input>
                    </div>
                    <div style="height: 20px"></div>
                    <div class="code_contain_code">
                        <div class="contain_item2_input">
                            <img src="@/assets/image/icon13.png" alt="" />
                            <el-input placeholder="请输入验证码" v-model="bindinfo.code">
                            </el-input>
                        </div>
                        <div class="getCode" @click="!counting && sendVerificationCode()">
                            {{ counting ? `${timer}s后重新获取` : "获取验证码" }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="contain_btn" @click="submitBinPhone">绑定手机号</div>
        </div>
    </div>
</template>

<script>
import { postJSON } from "/utils/request";

export default {
    props: ["backLoginInfo", "wxCode"],

    data() {
        return {
            bindinfo: {
                code: "",
                mobile: "",
            },
            counting: false,
            timer: 60,
            interval: null,
            code: '',
            token: ''
        }
    },
    created() {
        // 获取微信code
        // console.log("微信code!!!!!!!-------", this.wxCode);
    },
    watch: {
        $route(to, from) {
            console.log('111绑定---from', from);
            console.log('111绑定---to', to);
            this.code = to.query.code;
            // if (this.$route.query.code != undefined) {
            //     this.bindPhone = true
            //     this.wxCode = this.$route.query.code
            //     console.log("微信code!!!!!!!-------", this.$route.query.code);

            //     // this.getWeixin(this.$route.query.code, this.$route.query.state);
            // }
        },
        wxCode(newVal) {
            console.log('wxCode(newVal)', newVal);
            this.code = newVal
        }
    },
    methods: {
        sendVerificationCode() {
            // 模拟发送验证码的逻辑

            // var obj; //3验证码登录  2手机号注册
            if (!this.bindinfo.mobile) {
                this.$message.error("请输入手机号");
                return;
            }
            let obj = {
                phone: this.bindinfo.mobile,
                type: 2
            }

            // 设置定时器，每秒更新倒计时
            this.counting = true;
            postJSON("/send/sms", obj).then((res) => {
                console.log("zhixingwo ", res);
                this.startCountdown();
                // this.$message({
                //   message: res.message,
                // });
            });
        },
        startCountdown() {
            // 设置定时器，每秒更新倒计时
            this.interval = setInterval(() => {
                if (this.timer > 0) {
                    console.log("验证码参数：", this.timer);

                    this.timer--;
                } else {
                    // 倒计时结束时清除定时器
                    clearInterval(this.interval);
                    this.counting = false;
                    this.timer = 60;
                }
            }, 1000);
        },
        submitBinPhone() {
            if (window.localStorage.getItem('wxCode')) {
                // wxopenid
                let obj = {
                    ...this.bindinfo,
                    openid: window.localStorage.getItem('wxopenid')
                    // code: window.localStorage.getItem('wxCode')
                }
                console.log('微信扫码绑定返回值111', obj);
                // this.$emit("backLoginInfo");
                postJSON("/wxchat/register", obj).then((res) => {
                    console.log('/wxchat/register', res);
                    if (res.message !== 'success') {
                        this.$message.error(res.message);
                        return;
                    }

                    clearInterval(this.interval);
                    this.counting = false;
                    this.timer = 60;
                    this.token = res.data.token;
                    window.localStorage.setItem("token", res.data.token);
                    window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
                    this.$emit("backLoginInfo", res.data);
                });
                return
            }
            if (window.localStorage.getItem('auth_code')) {
                let obj = {
                    ...this.bindinfo,
                    openid: window.localStorage.getItem('aliopenid')
                    // code: window.localStorage.getItem('wxCode')
                }
                console.log('阿里扫码绑定返回值111', obj);
                // this.$emit("backLoginInfo");
                postJSON("/alipay/register", obj).then((res) => {
                    console.log('/alipay/register', res);
                    if (res.message !== 'success') {
                        this.$message.error(res.message);
                        return;
                    }

                    clearInterval(this.interval);
                    this.counting = false;
                    this.timer = 60;
                    this.token = res.data.token;
                    window.localStorage.setItem("token", res.data.token);
                    window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
                    this.$emit("backLoginInfo", res.data);
                });
                return
            }



            // postJSON("/wxchat/callback", obj).then((res) => {
            //     console.log('扫码绑定返回值22', res);
            //     if (res.message !== 'success') {
            //         this.$message.error(res.message);
            //         return;
            //     }

            //     clearInterval(this.interval);
            //     this.counting = false;
            //     this.timer = 60;


            //     this.token = res.data;
            //     window.localStorage.setItem("token", res.data);
            //     // window.localStorage.setItem("userInfo", JSON.stringify(res.data.user));
            //     this.$emit("backLoginInfo", res.data);


            // });
        },
    }
}
</script>

<style lang="scss" scoped>
.contain1 {
    width: 424px;
    height: 440px;
    background: #ffffff;
    border-radius: 6px;
    padding: 30px 0;
    box-sizing: border-box;

    .contain_item1 {
        margin: 0 0 30px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #333333;
        text-align: center;
    }

    .contain_item2 {
        margin: 0 55px 15px;

        .contain_item2_input {
            width: 314px;
            height: 50px;
            background: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding: 0 15px;
            box-sizing: border-box;

            img {
                width: 20px;
                height: 20px;
                margin-right: 6px;
            }

            ::v-deep .el-input__inner {
                border: none !important;
                padding-left: 0;
            }
        }
    }

    .contain_item3 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 55px;

        .contain_item3_left {
            height: 20px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #333333;

            .img,
            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }

        .contain_item3_right {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #999999;
            cursor: pointer;
        }
    }

    .contain_btn {
        width: 314px;
        height: 50px;
        background: linear-gradient(90deg, #23c48c, #33da61);
        border-radius: 6px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
        line-height: 50px;
        margin: 20px 55px 50px;
        cursor: pointer;
    }

    .loginMethods_contain {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 20px 0 55px;

        .loginMethods_contain_item {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #333333;
            margin-right: 20px;
            margin-bottom: 15px;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
                margin-right: 6px;
            }
        }
    }

    .code_contain {
        margin: 0 55px;

        .code_contain_number {
            .contain_item2_input {
                width: 314px;
                height: 50px;
                background: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                display: flex;
                align-items: center;
                padding: 0 15px;
                box-sizing: border-box;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                }

                ::v-deep .el-input__inner {
                    border: none !important;
                    padding-left: 0;
                }
            }
        }

        .code_contain_code {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .contain_item2_input {
                width: 186px;
                height: 50px;
                background: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                display: flex;
                align-items: center;
                padding: 0 15px;
                box-sizing: border-box;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                }

                ::v-deep .el-input__inner {
                    border: none !important;
                    padding-left: 0;
                }
            }

            .getCode {
                width: 108px;
                height: 50px;
                background: #23dc5a;
                border-radius: 6px;
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Semibold;
                font-weight: 600;
                text-align: center;
                color: #ffffff;
                line-height: 50px;
                // margin-left: 20px;
                cursor: pointer;
            }
        }
    }
}

.wx_dialog_toiast {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9854647;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .wx_dialog_toiast_delltet {
        width: 500px;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .wx_dialog_button_delete {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.wx_login_code {
    width: 196px;
    height: 196px;
    margin: 11px 67px;
    z-index: 10;
}

::v-deep .wrp_code,
.impowerBox {
    text-align: start !important;
}
</style>