<template>
  <div>
    <div class="tel_contain" v-if="type == 0">
      <div class="box1">
        <img src="@/assets/image/icon28.png" alt="" />郁金香文库客服（在线客服）
      </div>
      <!-- <div class="text1">电话：{{ phone }}</div> -->
      <div class="text1">QQ：1628290371</div>
      <div class="text1">工作日：08:00-18:00</div>
      <div class="box2">
        <div class="box2_item">
          <div class="box2_item_top">
            <img src="@/assets/image/icon80.png" alt="" />微信
          </div>
          <img src="@/assets/image/wx.jpg" alt="" class="code" />
        </div>
        <div class="box2_item">
          <div class="box2_item_top">
            <img src="@/assets/image/icon81.png" alt="" />QQ
          </div>
          <img src="@/assets/image/qq.jpg" alt="" class="code" />
        </div>
      </div>
    </div>
    <div class="tel_contain" v-else>
      <div class="box1">
        <img src="@/assets/image/icon28.png" alt="" />郁金香文库客服（在线客服）
      </div>
      <!-- <div class="text1">电话：{{ phone }}</div> -->
      <div class="text1">QQ：1628290371</div>
      <div class="text1">工作日：08:00-18:00</div>
      <div class="box2">
        <div class="box2_item">
          <div class="box2_item_top">
            <img src="@/assets/image/icon80.png" alt="" />微信
          </div>
          <img src="@/assets/image/wx.jpg" alt="" class="code" />
        </div>
        <div class="box2_item">
          <div class="box2_item_top">
            <img src="@/assets/image/icon81.png" alt="" />QQ
          </div>
          <img src="@/assets/image/qq.jpg" alt="" class="code" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "/utils/request";

export default {
  name: "telToast",
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    type: {
      default: "0",
      type: String,
    },
  },
  data() {
    return {
      drawer: false,
      phone: "",
    };
  },
  mounted() {
    get("/kefu/phone", {}).then((res) => {
      console.log(res);
      this.phone = res.data.value;
      // this.tag = res.data ?? [];
    });
  },
};
</script>

<style lang="scss">
.tel_contain {
  width: 277px;
  //   height: 286px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  // position: absolute;
  // left: 370px;
  box-shadow: 0px 3px 15px 0px rgba(161, 161, 161, 0.5);
  .box1 {
    img {
      width: 28px;
      height: 28px;
      margin-right: 12px;
    }
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #999999;
    letter-spacing: 0.7px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .text1 {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #999999;
    letter-spacing: 0.7px;
    text-align: center;
    margin-bottom: 11px;
  }
  .box2 {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
    .box2_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 17px;
      box-sizing: border-box;
      .box2_item_top {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        letter-spacing: 0.7px;
        margin-bottom: 12px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }
      .code {
        width: 91px;
        height: 91px;
      }
    }
  }
}
</style>