<template>
  <div>
    <div class="header">
      <div class="header_contain">
        <div class="header_left">
          <img src="@/assets/image/logo.png" alt="" class="logo" />
          <div class="header_left_tab">
            <div class="tab_item" :class="{ tab_item_active: $route.path == '/' }" @click="go(1)">
              <div>首页</div>
              <div class="line" :class="{ none_line: $route.path !== '/' }"></div>
            </div>
            <div class="tab_item" :class="{ tab_item_active: $route.path == '/documentClassify' }"
              style="position: relative; cursor: pointer" @mouseenter="showUploadContent1"
              @mouseleave="hideUploadContent1">
              <!-- @mouseenter="go(2)" -->
              <div class="sel">
                文档分类 <img src="@/assets/image/icon1.png" alt="" />
              </div>
              <div class="line" :class="{ none_line: $route.path !== '/documentClassify' }"></div>
              <!-- v-if="isclassify" -->
              <div class="category_popup_contain" v-if="isclassify" @mouseenter="cancelHide1" @mouseleave="startHide1">
                <div class="category_popup_contain_box">
                  <img src="@/assets/image/icon24.png" alt="" class="triangle" />
                  <div class="category_popup_contain_box_list">
                    <div class="category_popup_contain_box_list_left">
                      <div v-for="(item, index) in categoryList" :key="index" @mouseenter="changeCategory(item)"
                        @click="jumpDocClassify(item, 1)">
                        <div class="category_popup_contain_box_list_left_item" :class="{
              category_popup_contain_box_list_left_item_active:
                item.id == one_id,
            }">
                          {{ item.title }}
                        </div>
                      </div>
                    </div>
                    <div style="width: 50px"></div>
                    <div class="category_popup_contain_box_list_right">
                      <div class="category_popup_contain_box_list_right_item" v-for="(i, sub) in children" :key="sub"
                        @click="jumpDocClassify(i, 2)">
                        {{ i.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab_item" :class="{ tab_item_active: $route.path == '/vipList' }" @click="go(3)">
              <div>VIP专区</div>
              <div class="line" :class="{ none_line: $route.path !== '/vipList' }"></div>
            </div>
            <div class="tab_item" :class="{
              tab_item_active:
                $route.path == '/article' ||
                $route.path == '/tutor' ||
                $route.path == '/edit',
            }" style="position: relative; cursor: pointer" @mouseenter="go(4)" @mouseleave="isdocument = false">
              <div>文档服务</div>
              <div class="line" :class="{
              none_line:
                $route.path !== '/article' ||
                $route.path !== '/tutor' ||
                $route.path !== '/edit',
            }"></div>
              <div class="centerToast1" v-if="isdocument">
                <div class="centerToast_box">
                  <img src="@/assets/image/icon24.png" alt="" class="triangle" />
                  <div class="centerToast_box_item" v-for="(item, index) in document" :key="index"
                    @click="jumpDoc(item)">
                    <div class="title">{{ item.name }}</div>
                    <div class="line" :class="{ active_line: document.length - 1 == index }"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab_item" :class="{ tab_item_active: $route.path == '/invite' }" @click="go(5)">
              <div>邀请有礼</div>
              <div class="line" :class="{ none_line: $route.path !== '/invite' }"></div>
            </div>
            <!-- <div class="tab_item" :class="{ tab_item_active: $route.path == '/fufei' }" @click="$router.push('/fufei')">
              <div>付费资讯</div>
              <div class="line" :class="{ none_line: $route.path !== '/fufei' }"></div>
            </div> -->
          </div>
          <div class="header_left_search" v-if="$route.path == '/documentClassify'">
            <div style="display: flex; align-items: center">
              <img src="@/assets/image/icon2.png" alt="" />
              <el-input v-model="keyword" placeholder="请输入关键字"></el-input>
            </div>
            <div style="display: flex; align-items: center">
              <div class="search_line"></div>
              <div class="search_text" @click="search">搜索文档</div>
            </div>
          </div>
        </div>
        <div class="header_right">
          <div class="header_right_tab1">
            <div @mouseenter="showUploadContent" @mouseleave="hideUploadContent" class="upload-trigger">
              我要上传
            </div>
            <div class="centerToast" v-show="isupload" @mouseenter="cancelHide" @mouseleave="startHide">
              <div class="centerToast_box">
                <img src="@/assets/image/icon24.png" alt="" class="triangle" />
                <div class="centerToast_box_item" v-for="(item, index) in upload" :key="index"
                  @click="upload_click(item)">
                  <div class="title">{{ item.name }}</div>
                  <div class="line" :class="{ active_line: upload.length - 1 == index }"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="header_right_tab1_1" @click="go(6)">文档管理</div>
          <div class="header_right_tab2">
            <img src="@/assets/image/icon3.png" alt="" class="img1" style="margin-right: 12px" @click="jumpNoticList" />
            <div class="info" @click.stop="openUserContain">
              <img v-if="info" :src="info.avatar ? `${$imgURL}${info.avatar}` : `${touxiang}`" alt="" class="img1" />
              <div style="
                  margin: 0 6px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 60px;
                " v-if="info != null">
                {{ info.username }}
              </div>
              <img src="@/assets/image/icon1.png" alt="" class="img2" />
              <div :class="screenWidth > 1600 ? 'centerToast' : 'centerToast2'" v-if="isuserCenter">
                <div class="centerToast_box">
                  <img src="@/assets/image/icon24.png" alt="" class="triangle" />
                  <div class="centerToast_box_item" v-for="(item, index) in userCenter" :key="index"
                    @click="gouserCenter(item)">
                    <div class="title">{{ item.name }}</div>
                    <div class="line" :class="{ active_line: userCenter.length - 1 == index }"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 78px"></div>
    <div style="max-height: 100vh; overflow: auto;">
      <!-- <div style="min-height: 500px;"> -->
      <router-view />
      <!-- </div> -->
      <!-- <div style="width: 100%;height: 391px;"></div> -->
      <div class="footer">
        <div class="footerContain">
          <div class="footerContain_top">
            <div class="box1">
              <div class="box1_title" @click="jumpRichText('bqcl')">版权处理</div>
              <div class="box1_text" @click="jumpRichText('bqsm')">版权声明</div>
              <div class="box1_text" @click="jumpRichText('qqcl')">侵权处理</div>
              <div class="box1_text" @click="jumpRichText('mzsm')">免责声明</div>
              <div class="box1_text" @click="jumpRichText('yszc')">隐私政策</div>
            </div>
            <div class="box1">
              <div class="box1_title" @click="jumpRichText('sybz')">使用帮助</div>
              <div class="box1_text" @click="jumpRichText('yhxy')">用户协议</div>
              <div class="box1_text" @click="jumpRichText('hysm')">会员说明</div>
              <div class="box1_text" @click="jumpRichText('sqxz')">上传下载</div>
              <div class="box1_text" @click="jumpRichText('tgbz')">投稿帮助</div>
            </div>
            <div class="box1">
              <div class="box1_title">关于</div>
              <div class="box1_text" @click="jump(`/aboutMe`)">关于网站</div>
              <div class="box1_text" @click="jumpRichText('lxwm')">联系我们</div>
              <div class="box1_text" @click="jumpRichText('qywh')">企业文化</div>
              <div class="box1_text" @click="jumpRichText('gsys')">公司优势</div>
              <div class="box1_text" @click="jumpRichText('dwhz')">对外合作</div>
            </div>
          </div>
          <div class="footerContain_bottom">
            <div class="footerContain_bottom_text">
              郁金香文库©️2019–2023 | 站点地图
            </div>
            <div class="footerContain_bottom_text" style="display: flex; margin: 6px 0">
              <p style="margin-right: 46px" @click="link()">京ICP备2024048811号-1</p>
              <p>京公网安备 44030402005740号</p>
            </div>
            <div class="footerContain_bottom_text">
              本站为【郁金香文库】，本站所上传文档，仅供参考使用，如侵犯您的权益，请联系我们进行删除
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { get, del } from "/utils/request";
import icon52 from "@/assets/image/icon52.png";
import { EventBus } from '/utils/eventBus.js';
export default {
  data() {
    return {
      input: "",
      isclassify: false, //文档分类显示
      isdocument: false, //文档服务显示
      isupload: false, //文档上传显示
      isuserCenter: false, //个人中心显示
      userCenter: [
        { id: 1, name: "个人中心" },
        // { id: 2, name: "个人主页" },
        { id: 3, name: "我的订单" },
        { id: 4, name: "文档管理" },
        { id: 5, name: "退出登录" },
      ], //用户中心弹窗数据
      upload: [
        { id: 1, name: "新手指南" },
        { id: 2, name: "常见问题" },
        { id: 3, name: "立即上传" },
      ], //上传文档弹窗数据
      document: [
        { id: 1, name: "文章定制" },
        { id: 2, name: "硕博辅导" },
        { id: 3, name: "修改降重" },
      ],
      keyword: "",
      // info: JSON.parse(localStorage.getItem("userInfo")),
      info: null,
      categoryList: [],
      // children: [],
      one_id: "",
      // two_id:1,
      children: [
        { id: 2, name: "论文" },
        { id: 3, name: "论文" },
        { id: 2, name: "论文" },
      ],
      hideTimer: null,
      hideTimer1: null,
      screenWidth: 0,
      touxiang: icon52

    };
  },
  created() {
    // this.$EventBus.$on("localStorageUpdated", this.updateAppData);
    // 组件创建时，将localStorage的数据加载到localData
    // this.loadLocalData();
    // // 监听storage事件，以便当数据发生变化时更新localData
    // window.addEventListener('storage', this.handleStorageChange);

    this.checkLoginState();
    EventBus.$on('loginStateChanged', this.checkLoginState);

    // this.info = localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):null
  },
  mounted() {
    this.getCategoryList();
    this.updateScreenWidth(); // 初始化宽度
    window.addEventListener("resize", this.updateScreenWidth); // 监听窗口大小变化
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth); // 移除监听器
    // window.removeEventListener('storage', this.handleStorageChange);
  },
  watch: {
    "$localStorage.userInfo": {
      handler: function (newValue, oldValue) {
        // 这里是数据变化后的回调函数
        console.log("myData changed:", newValue, oldValue);
      },
      deep: true,
    },
    $route(to, from) {
      console.log("to", to);
      console.log("from", from);
      // if(to.query.type){
      //   this.$router.push({ path: "/personalCenter", query: { type: to.query.type } });
      // }
    },
  },
  methods: {
    checkLoginState() {
      const userData = JSON.parse(localStorage.getItem('userInfo'));
      if (userData) {
        this.info = userData;
      } else {
        this.info = null;
      }
    },
    loadLocalData() {
      // 从localStorage加载数据到localData
      this.info = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;
    },
    handleStorageChange(event) {
      console.log('handleStorageChange', event);

      // 当storage事件触发时，重新加载数据
      // if (event.storageArea === localStorage) {
      this.loadLocalData();
      // }
    },
    link() {
      window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
      console.log("this.screenWidth", this.screenWidth);
    },
    showUploadContent() {
      clearTimeout(this.hideTimer); // Cancel any pending hide operation
      this.isupload = true;
    },
    hideUploadContent() {
      // Start hiding content after a short delay
      this.hideTimer = setTimeout(() => {
        this.isupload = false;
      }, 200); // Adjust this delay as needed
    },
    showUploadContent1() {
      clearTimeout(this.hideTimer1); // Cancel any pending hide operation
      this.isclassify = true;
    },
    hideUploadContent1() {
      // Start hiding content after a short delay
      this.hideTimer1 = setTimeout(() => {
        this.isclassify = false;
      }, 100); // Adjust this delay as needed
    },
    cancelHide1() {
      clearTimeout(this.hideTimer1); // Cancel the hide operation
    },
    startHide1() {
      this.hideUploadContent1(); // Start hiding content when leaving the content area
    },
    cancelHide() {
      clearTimeout(this.hideTimer); // Cancel the hide operation
    },
    startHide() {
      this.hideUploadContent(); // Start hiding content when leaving the content area
    },
    updateAppData() {
      // 更新你的数据逻辑
      this.info = JSON.parse(localStorage.getItem("userInfo"));
    },
    go(type) {
      if (type == 1) {
        this.$router.push("/");
      } else if (type == 2) {
        this.isclassify = !this.isclassify;
        // this.$router.push("/documentClassify");
      } else if (type == 3) {
        if (!window.localStorage.getItem("token")) {
          this.$message.warning("请先登录");
          return;
        }
        get("/info/user", {}).then((res) => {
          console.log("res", res);
          let vip = res.data.is_vip;
          if (vip == 1) {
            window.open(this.$router.resolve({ path: `/vipList` }).href, '_blank');
            // this.$router.push("/vipList");
          } else {
            this.$message({
              message: "加入会员后，即可查看VIP专区",
              type: "warning",
            });
            window.open(this.$router.resolve({ path: `/buyVip` }).href, '_blank');

            // this.$router.push("/buyVip");
            return;
          }
          // let vip = JSON.parse(window.localStorage.getItem("userInfo")).is_vip; //1是 2否
        });

      } else if (type == 4) {
        this.isdocument = true;
      } else if (type == 5) {
        if (!window.localStorage.getItem("token")) {
          this.$message.warning("请先登录");
          return;
        }
        window.open(this.$router.resolve({ path: `/invite` }).href, '_blank');

        // this.$router.push("/invite");
      } else if (type == 6) {
        if (!window.localStorage.getItem("token")) {
          this.$message.warning("请先登录");
          return;
        }
        window.open(this.$router.resolve({ path: `/myDocument` }).href, '_blank');

        // this.$router.push("/myDocument");
      }
    },
    jump(path) {
      if (path == "/aboutMe") {
        this.$router.push({ path: "/aboutMe", query: { text: "about" } });
        return;
      }
      this.$router.push(path);
    },
    gouserCenter(item) {
      if (item.id == 1) {
        this.$router.push({ path: "/personalCenter", query: { type: 1 } });
        // this.$router.go(0);
      } else if (item.id == 2) {
        this.$router.push("/myDocument");
      } else if (item.id == 3) {
        this.$router.push({ path: "/personalCenter", query: { type: 10 } });
      } else if (item.id == 4) {
        this.$router.push("/myDocument");
      } else {
        this.logout();
      }
    },
    logout() {
      del("/user/logout").then((res) => {
        console.log(res);
        if (Object.getOwnPropertyNames(res).length == 0) {
          // localStorage.clear();
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
          this.$message.success("退出成功");
          this.$router.replace("/");
          this.$router.go(0);
          // if (this.$router.path == '/') return this.$router.push('/')
          // this.$router.push({name:'Home'});
        }
      });
    },
    jumpRichText(item) {
      this.$router.push({ path: "/richText", query: { text: item } });
    },
    jumpDoc(item) {
      if (item.id == 1) {
        window.open(this.$router.resolve({ path: `/article` }).href, '_blank');
        
        // this.$router.push({ path: "/article" });
      } else if (item.id == 2) {
        window.open(this.$router.resolve({ path: `/tutor` }).href, '_blank');

        // this.$router.push({ path: "/tutor" });
      } else {
        window.open(this.$router.resolve({ path: `/edit` }).href, '_blank');

        // this.$router.push({ path: "/edit" });
      }
    },
    upload_click(item) {
      this.isupload = false;
      if (item.id == 1 || item.id == 2) {
        // { id: 1, name: "新手指南" },
        // { id: 2, name: "常见问题" },
        var text = "";
        if (item.id == 1) {
          text = "xszn";
        } else {
          text = "cjwt";
        }
        window.open(this.$router.resolve({ path: `/richText?text=${text}` }).href, '_blank');

        // this.$router.push({ path: "/richText", query: { text } });
      } else if (item.id == 3) {
        if (!window.localStorage.getItem("token")) {
          this.$message.warning("请先登录");
          this.$router.replace({ path: "/" });
          return;
        }
        window.open(this.$router.resolve({ path: `/upload` }).href, '_blank');

        // this.$router.push({ path: "/upload" });
      }
    },
    search() {
      if (!this.keyword) {
        this.$message.warning("请输入搜索关键字");
        return;
      }
      this.$router.push({
        name: "search",
        query: { keyword: this.keyword },
      });
    },
    jumpNoticList() {
      this.$router.push({
        name: "noticeList",
      });
    },
    getCategoryList() {
      get("/category/list").then((res) => {
        console.log(res);
        // this.categoryList = res.category;
        this.one_id = res.category.length > 0 ? res.category[0].id : "";
        let arr = [];
        let arr2 = [];
        res.category.map((item) => {
          if (!item.parent_id) {
            arr.push(item);
          }
          if (item.parent_id == res.category[0].id) {
            arr2.push(item);
          }
        });
        this.categoryList = arr;
        this.children = arr2;
      });
    },
    changeCategory(item) {
      this.one_id = item.id;
      console.log(this.one_id);

      // this.children = [];
      get("/category/list").then((res) => {
        // console.log(res);
        let arr2 = [];
        res.category.map((i) => {
          if (i.parent_id == item.id) {
            arr2.push(i);
          }
        });
        this.children = arr2;
        console.log(this.children);
      });
    },
    jumpDocClassify(item, type) {
      console.log("当前分类", item);
      this.isclassify = false;
      if (type == 1) {
        //1:一级分类 2：二级分类
        window.open(this.$router.resolve({ path: `/documentClassify?id=${item.id}` }).href, '_blank');
        // this.$router.push({ name: "documentClassify", query: { id: item.id } });
      } else {
        window.open(this.$router.resolve({ path: `/documentClassify?id=${item.id}` }).href, '_blank');
        // this.$router.push({
        //   name: "documentClassify",
        //   query: { id: item.id, parent_id: item.parent_id },
        // });
      }
    },
    openUserContain() {
      if (!window.localStorage.getItem("token")) {
        this.$message.warning("请先登录");
        return;
      }
      this.isuserCenter = !this.isuserCenter;
    },
  },
};
</script>

<style lang="scss">
@import url("./assets/css/reset.css");

.header {
  width: 100%;
  height: 78px;
  background: #28cb7d;
  position: fixed;
  top: 0;
  z-index: 10;

  .header_contain {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header_left {
      display: flex;
      align-items: center;

      .logo {
        width: 104px;
        height: 77px;
        margin-right: 40px;
      }

      .header_left_tab {
        display: flex;
        align-items: center;
        cursor: pointer;

        .tab_item_active {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #ffffff;
          letter-spacing: 0.9px;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-right: 30px;

          .line {
            width: 24px;
            height: 2px;
            background: #ffffff;
            margin-top: 6px;
          }

          .none_line {
            width: 24px;
            height: 2px;
            background: transparent;
            margin-top: 6px;
          }
        }

        .tab_item {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ffffff;
          letter-spacing: 0.9px;
          // margin-right: 30px;
          margin-right: 20px;

          .sel {
            display: flex;
            align-items: center;

            img {
              width: 21px;
              height: 21px;
            }
          }

          .line {
            width: 24px;
            height: 2px;
            background: #ffffff;
            margin-top: 6px;
          }

          .none_line {
            width: 24px;
            height: 2px;
            background: transparent;
            margin-top: 6px;
          }

          .centerToast1 {
            position: absolute;
            top: 40px;
            left: -58px;

            .centerToast_box {
              width: 184px;
              // height: 332px;
              background: #ffffff;
              border-radius: 8px;
              position: relative;
              padding: 20px 22px 0;
              box-sizing: border-box;

              .triangle {
                position: absolute;
                top: -8px;
                left: 85px;
                width: 23px;
                height: 13px;
              }

              .centerToast_box_item {
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: center;
                color: #333333;

                .title {
                  margin: 20px 0;
                }

                .title:hover {
                  font-size: 16px;
                  font-family: PingFang SC, PingFang SC-Regular;
                  font-weight: 400;
                  text-align: center;
                  color: #23dc5a;
                }

                .line {
                  width: 100%;
                  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                }

                .active_line {
                  width: 100%;

                  border-bottom: 1px dashed rgba(0, 0, 0, 0);
                }
              }
            }
          }
        }
      }

      .header_left_search {
        width: 379px;
        height: 52px;
        background: #ffffff;
        border-radius: 6px;
        padding: 0 15px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }

        .el-input__inner {
          border: none !important;
          padding-left: 0;
        }

        .search_line {
          width: 1px;
          height: 25px;
          background: #efefef;
        }

        .search_text {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #23dc5a;
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }

    .header_right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header_right_tab1 {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 0.9px;
        margin-right: 30px;
        position: relative;
        cursor: pointer;

        // .centerToast:hover {
        //   display: block !important;
        // }
        .centerToast {
          position: absolute;
          top: 40px;
          left: -58px;

          .centerToast_box {
            width: 184px;
            // height: 332px;
            background: #ffffff;
            border-radius: 8px;
            position: relative;
            padding: 20px 22px 0;
            box-sizing: border-box;
            pointer-events: none; //

            .triangle {
              position: absolute;
              top: -8px;
              left: 85px;
              width: 23px;
              height: 13px;
            }

            .centerToast_box_item {
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: center;
              color: #333333;
              pointer-events: auto;

              /* 允许该元素捕获鼠标事件 */
              .title {
                margin: 20px 0;
              }

              .title:hover {
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: center;
                color: #23dc5a;
              }

              .line {
                border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
              }

              .active_line {
                border-bottom: 1px dashed rgba(0, 0, 0, 0);
              }
            }
          }
        }

        .centerToast:hover {
          display: block !important;
        }
      }

      .header_right_tab1_1 {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 0.9px;
        margin-right: 30px;
        cursor: pointer;
      }

      .header_right_tab2 {
        display: flex;
        align-items: center;

        .info {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ffffff;
          letter-spacing: 0.8px;
          position: relative;
          cursor: pointer;

          .centerToast {
            position: absolute;
            top: 40px;
            left: -82px;

            .centerToast_box {
              width: 184px;
              // height: 332px;
              background: #ffffff;
              border-radius: 8px;
              position: relative;
              padding: 20px 22px 0;
              box-sizing: border-box;

              .triangle {
                position: absolute;
                top: -8px;
                left: 85px;
                width: 23px;
                height: 13px;
              }

              .centerToast_box_item {
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: center;
                color: #333333;

                .title {
                  margin: 20px 0;
                }

                .title:hover {
                  font-size: 16px;
                  font-family: PingFang SC, PingFang SC-Regular;
                  font-weight: 400;
                  text-align: center;
                  color: #23dc5a;
                }

                .line {
                  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                }

                .active_line {
                  border-bottom: 1px dashed rgba(0, 0, 0, 0);
                }
              }
            }
          }

          .centerToast2 {
            position: absolute;
            top: 40px;
            left: -110px;

            .centerToast_box {
              width: 184px;
              // height: 332px;
              background: #ffffff;
              border-radius: 8px;
              position: relative;
              padding: 20px 22px 0;
              box-sizing: border-box;

              .triangle {
                position: absolute;
                top: -8px;
                left: 85px;
                width: 23px;
                height: 13px;
              }

              .centerToast_box_item {
                font-size: 16px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                text-align: center;
                color: #333333;

                .title {
                  margin: 20px 0;
                }

                .title:hover {
                  font-size: 16px;
                  font-family: PingFang SC, PingFang SC-Regular;
                  font-weight: 400;
                  text-align: center;
                  color: #23dc5a;
                }

                .line {
                  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                }

                .active_line {
                  border-bottom: 1px dashed rgba(0, 0, 0, 0);
                }
              }
            }
          }
        }

        .img1 {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }

        .img2 {
          width: 21px;
          height: 21px;
        }
      }
    }
  }
}

.footer {
  .footerContain {
    width: 100%;
    height: 391px;
    background: #fff;
    // position: fixed;
    // bottom: 0;
    padding: 50px 414px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footerContain_top {
      display: flex;
      justify-content: space-between;

      .box1 {
        .box1_title {
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #333333;
          letter-spacing: 0.81px;
          margin-bottom: 20px;
        }

        .box1_text {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #666666;
          letter-spacing: 0.63px;
          margin-bottom: 15px;
          cursor: pointer;
        }
      }
    }

    .footerContain_bottom {
      display: flex;
      align-items: center;
      flex-direction: column;

      .footerContain_bottom_text {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #999999;
        letter-spacing: 0.54px;
      }
    }
  }
}

.category_popup_contain {
  position: absolute;
  top: 40px;
  left: 42%;
  transform: translate(-58%, 0%);

  .category_popup_contain_box {
    // max-width: 374px;
    // height: 332px;
    background: #fff;
    border: 1px solid #ffffff;
    border-radius: 8px;
    box-shadow: 5px 4px 19px 0px rgba(191, 203, 221, 0.2);
    backdrop-filter: blur(23.7px);
    position: relative;
    padding: 0 22px;
    box-sizing: border-box;

    .triangle {
      position: absolute;
      width: 23px;
      height: 13px;
      top: -8px;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    .category_popup_contain_box_list {
      display: flex;
      justify-content: space-between;

      .category_popup_contain_box_list_left {
        width: 140px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;

        // min-height: 332px;
        // display: flex;
        // flex-direction: column;
        // overflow-y: scroll;
        // overflow: scroll;
        .category_popup_contain_box_list_left_item {
          width: 140px;
          height: 62px;
          text-align: center;
          line-height: 62px;
          border-bottom: 1px dashed #e5e5e5;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // display: flex;
          // flex-direction: column;
        }

        .category_popup_contain_box_list_left_item_active {
          width: 140px;
          // min-height: 62px;
          text-align: center;
          line-height: 62px;
          border-bottom: 1px dashed #e5e5e5;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #23dc5a;
          display: flex;
          flex-direction: column;
        }
      }

      .category_popup_contain_box_list_right {
        width: 140px;

        .category_popup_contain_box_list_right_item {
          display: flex;
          flex-direction: column;
          width: 140px;
          min-height: 62px;
          max-height: 62px;
          text-align: center;
          line-height: 62px;
          border-bottom: 1px dashed #e5e5e5;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>